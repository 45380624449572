import './reset.css';
import './App.css';
import Home from './pages/home';
import useLocalStorage from 'util/useLocalStorage';

export default function App() {
	const [theme, setTheme] = useLocalStorage('data-theme', 'dark');

	function onChangeTheme() {
		setTheme(theme === 'light' ? 'dark' : 'light');
	}

	return (
		<div className='App' data-theme={theme}>
			<Home />
		</div>
	);
}

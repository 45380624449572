import './styles.css';

export default function Home() {
	return (
		<div className='home-wrapper'>
			<div className='home-container'>
				<h1 className='home-title'>Under construction!</h1>
			</div>
		</div>
	);
}
